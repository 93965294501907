import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import '../styles/head.css'
import { NavLink } from 'react-router-dom'
import { fetchTools } from '../store/tools.reducer'

function Head({ cartItems, fetchTools}) {
    const [search, setSearch] = useState('')
    const [statusIcon, setStatusIcon] = useState('close')

    const onChangeSearch = e => {
        setSearch(e.target.value)
    }

    const toSearch = e => {
        fetchTools(search)
    }

    useEffect(() => {
    }, [cartItems])

    return(
        <div className='head'>
            <div className='nav-head'>
                <div className='wrapper'>
                    <NavLink to={'/'}><img src='/images/logo.svg' className='nav-logo' alt='карат-сервис жодино'/></NavLink>
                    <label htmlFor='toggle-1' className='toggle-menu' id='tm' onClick={() => setStatusIcon(statusIcon === 'open' ? 'close' : 'open')}>
                        <i className={statusIcon}></i>
                        <i className={statusIcon}></i>
                        <i className={statusIcon}></i>
                    </label>
                    <input type='checkbox' id='toggle-1'/>
                    <ul className='nav-container'>
                        <li className='nav-li'><NavLink className='nav-a' to='/'>Главная</NavLink></li>
                        <li className='nav-li'><NavLink className='nav-a' to='/catalog'>Каталог</NavLink></li>
                        <li className='nav-li'><NavLink className='nav-a' to='/about'>О нас</NavLink></li>
                        <li className='nav-li nav-search'>
                            <input className='nav-input' placeholder='Название товара...' onChange={onChangeSearch}/>
                            <NavLink to='/search' onClick={e => toSearch(search)}><img src='/images/search.svg' className='search-ico' alt='Искать товар'/></NavLink>
                        </li> 
                    </ul>
                    <NavLink className='nav-cart' to='/cart'><div className='nav-count'>{cartItems.length}</div></NavLink>   
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tools: state.tools,
    cartItems: state.cartItems
})

const mapDispatchToProps = {
    fetchTools
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Head)
import React, { useState } from 'react'

import '../styles/modalItem.css'

import ModalAddSuccess from './ModalAddSuccess'

function ModalItem ({ item, onHide, addToCart }) {
    const [showOk, setShowOk] = useState(false)

    const addItem = (item) => {
        addToCart (item)
        setShowOk(true)
    }

    const closeModal = () => {
        const body = document.querySelector('body');
        body.style.overflowY = 'auto';
        onHide()
    }

    const body = document.querySelector('body')
    body.style.overflowY = 'hidden'

    return <div className='modal'>
        <div className='modal__item'>
            <div className='modal__cancel_button' onClick={e => closeModal()}></div>
            <div className='item_info'>
                <div className='wrap-img'><img className='info-img' src={item.image} alt='строительные материалы'/></div>
                <div className='info-item-desc'>
                    <div className='info-title'>{item.title}</div>
                    
                    <div className='info-brand'>Производитель: {item.brand}</div>
                    {
                        item.price == 0 ?
                        <div className='info-price'>нет в наличии</div>
                        :
                        <>
                        <div className='info-price'>{item.price} BYN</div>
                        <button className='info-button' onClick={ e => addItem(item)}>добавить в корзину</button>
                        </>
                    }
                    
                </div>
            </div> 
            <div className='info-des'>Описание:</div>
            <div className='info-description'>{item.description}</div>  
        </div>
        {
            showOk ? 
            <ModalAddSuccess onHide={e => setShowOk(false)}/>
            : null
        }
    </div>
}

export default ModalItem

/**
 * <div className='info-item'>
                <div className='wrap-img'><img className='info-img' src={item.image} alt='строительные материалы'/></div>
                <div className='info-item-desc'>
                    <div className='info-title'>{item.title}</div>
                    <div className='info-brand'>Бренд: {item.brand}</div>
                    <div className='info-price'>{item.price}</div>
                </div>
            </div>
            <div className='info-description'>{item.description}</div>
 */
import React, { useState } from 'react'
import '../styles/menu.css'

import { connect } from 'react-redux'
import { showTools, filterTools } from '../store/tools.reducer'
import $ from 'jquery'

function Menu({ showTools, filterTools }){
    const [checked, setChecked] = useState(false)

    return(
        <div className='menu'>
            <label htmlFor='toggle-2' className='toggle-menu2'>Категории<img src='/images/ic.svg' alt='taifun'/></label>
            <input type='checkbox' className='input-menu' id='toggle-2' checked={checked} onClick={e => setChecked(!checked)}/>
            <div className="dropdown0">
            <div className="dropdown">
                <button className="dropbtn" onClick={e => $('#d4').toggleClass('block')}>Строительные смеси</button>
                <div className="dropdown-content" id='d4'>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'shtukaturka'); setChecked(false) }}>Штукатурка</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'shtukaturka', 'ilmax')}>ilmax</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'shtukaturka', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'shpatlevka'); setChecked(false)}}>Шпатлевка</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'shpatlevka', 'ilmax')}>ilmax</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'shpatlevka', 'condor')}>condor</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'shpatlevka', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'phyga'); setChecked(false) }}>Фуга</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'phyga', 'ilmax')}>ilmax</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'phyga', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'shtucaturka_decorativnaya'); setChecked(false) }}>Декоративная штукатурка</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'shtucaturka_decorativnaya', 'ilmax')}>ilmax</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'shtucaturka_decorativnaya', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'gidroizolyatiya'); setChecked(false) }}>Гидроизоляция</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'gidroizolyatiya', 'ilmax')}>ilmax</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'gidroizolyatiya', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'kladochnie_sostavi'); setChecked(false) }}>Кладочные составы</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'kladochnie_sostavi', 'ilmax')}>ilmax</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'kladochnie_sostavi', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'klei_dly_plitki'); setChecked(false) }}>Клеи для плитки</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'klei_dly_plitki', 'ilmax')}>ilmax</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'klei_dly_plitki', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'klei_dly_sistem_yteplenia'); setChecked(false) }}>Клеи для систем утепления</button>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'montazhnie_sostavi'); setChecked(false) }}>Монтажные составы</button>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'smesi_dly_pola'); setChecked(false) }}>Смеси для пола</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'klei_dly_plitki', 'ilmax')}>ilmax</button>
                            <button className='menu-item' onClick={e => filterTools('stroitelnie_smesi', 'klei_dly_plitki', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'tement'); setChecked(false) }}>Цемент, известь, мел, гипс</button>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('stroitelnie_smesi', 'zaschitnie_sostavi'); setChecked(false) }}>Защитные составы</button>
                    </div>
                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn" onClick={e => (showTools('gruntovki', ''), $('#d2').toggleClass('block'))}>Грунтовки</button>
                <div className="dropdown-content" id='d2'>
                    <button className='menu-item' onClick={e => { filterTools('gruntovki', '', 'ilmax'); setChecked(false) }}>ilmax</button>
                    <button className='menu-item' onClick={e => { filterTools('gruntovki', '', 'condor'); setChecked(false) }}>condor</button>
                    <button className='menu-item' onClick={e => { filterTools('gruntovki', '', 'taifun'); setChecked(false) }}>тайфун</button>
                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn" onClick={e => (showTools('kraski', ''), $('#d3').toggleClass('block'))}>Краски</button>
                <div className="dropdown-content" id='d3'>
                    <button className='menu-item' onClick={e => { filterTools('kraski', '', 'condor'); setChecked(false) }}>condor</button>
                    <button className='menu-item' onClick={e => { filterTools('kraski', '', 'taifun'); setChecked(false) }}>тайфун</button>
                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn" onClick={e => { showTools('derevozaschita', ''); setChecked(false) }}>Деревозащита</button>
            </div>
            <div className="dropdown">
                <button className="dropbtn" onClick={e => { showTools('gipsocarton', ''); setChecked(false) }}>Гипсокартон</button>
            </div>
            <div className="dropdown">
                <button className="dropbtn" onClick={e => { showTools('krovelnie_materiali', ''); setChecked(false) }}>Гидроизоляционные материалы</button>
            </div>
            <div className="dropdown">
                <button className="dropbtn" onClick={e => $('#d5').toggleClass('block')}>Пены и герметики</button>
                <div className="dropdown-content" id='d5'>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('peni_i_germetiki', 'peni'); setChecked(false) }}>Пены</button>
                        <div className="dropdown-content2">
                            <button className='menu-item' onClick={e => filterTools('peni_i_germetiki', 'peni', 'mixfor')}>mixfor</button>
                            <button className='menu-item' onClick={e => filterTools('peni_i_germetiki', 'peni', 'condor')}>condor</button>
                            <button className='menu-item' onClick={e => filterTools('peni_i_germetiki', 'peni', 'taifun')}>тайфун</button>
                        </div>
                    </div>
                    <div className="dropdown2">
                        <button className="dropbtn2" onClick={e => { showTools('peni_i_germetiki', 'germetiki'); setChecked(false) }}>Герметики</button>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tools: state.tools
});

const mapDispatchToProps = {
    showTools,
    filterTools,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Menu)
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchTools } from '../store/tools.reducer'
import { addToCart } from '../store/cart.reducer'

import Head from '../components/Head'
import ToolsList from '../components/ToolsList'
import Footer from '../components/Footer'

import '../styles/main.css'

function Search({ tools, addToCart }) {
    const body = document.querySelector('body')
    body.style.overflowY = 'auto'
    
    return (<div>
        <Head/>
        <div className='wrapper-catalog'>
            {
                tools.length ? 
                <div className='search-list'>
                    <ToolsList tools={tools} addToCart={addToCart}/>
                </div>
                :
                <div className='cart_empty'>По Вашему запросу ничего не найдено :(</div>
            } 
        </div>
        <Footer/>
    </div>) 
}

const mapStateToProps = (state) => ({
    tools: state.tools,
})

const mapDispatchToProps = {
    fetchTools,
    addToCart
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Search)
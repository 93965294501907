import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import '../styles/top.css'
import Fade from 'react-reveal/Fade'

import { fetchTop } from '../store/tools.reducer'
import { addToCart } from '../store/cart.reducer'

import ModalItem from './ModalItem'
import ModalAddSuccess from './ModalAddSuccess'

function TopSales({fetchTop, addToCart, tools}) {
    const [showItem, setShowItem] = useState(false)
    const [showOk, setShowOk] = useState(false)
    const [item, setItem] = useState()

    const addItem = (item) => {
        addToCart (item)
        setShowOk(true)
    }

    const onItemClick = (item) => {
        setItem(item)
        setShowItem(true)
    }

    useEffect(() => {
        fetchTop()              
     },[])
 
     useEffect(() => {
     }, [tools])
    
    return(
        <div className='top'>
            <h2 className='top-h2'><img src='/images/Rectangle.svg' alt='square'/>  Выберете свой товар  <img src='/images/Rectangle.svg' alt='square'/></h2>
            <div className='wrap-top-list'>
                {
                    (tools.length == 0) ? <div className='wrapper-loader'><img className='loader' src='/images/loader.svg' alt='loading...'/></div>: 
                    <div className='list-top'>
                        <div className='top-row'>
                            <div className='top-item'>
                                <div className='top-img-wrap' onClick={e => onItemClick(tools[0])}><Fade left><img className='top-img' src={tools[0].image} alt='строительные материалы'/></Fade></div>
                                <p className='top-title' onClick={e => onItemClick(tools[0])}>{tools[0].title}</p>
                                <Fade top><p className='top-price'>{tools[0].price} byn</p></Fade>
                                <button className='top-button' onClick={ e =>  addItem(tools[0])}>добавить в корзину</button>
                            </div>
                            <div className='top-item'>
                                <div className='top-img-wrap' onClick={e => onItemClick(tools[1])}><Fade left><img className='top-img' src={tools[1].image} alt='строительные материалы'/></Fade></div>
                                <p className='top-title' onClick={e => onItemClick(tools[1])}>{tools[1].title}</p>
                                <Fade top><p className='top-price'>{tools[1].price} byn</p></Fade>
                                <button className='top-button' onClick={ e => addItem(tools[1])}>добавить в корзину</button>
                            </div>
                            <div className='top-item'>
                                <div className='top-img-wrap' onClick={e => onItemClick(tools[2])}><Fade left><img className='top-img' src={tools[2].image} alt='строительные материалы'/></Fade></div>
                                <p className='top-title' onClick={e => onItemClick(tools[2])}>{tools[2].title}</p>
                                <Fade top><p className='top-price'>{tools[2].price} byn</p></Fade>
                                <button className='top-button' onClick={ e => addItem(tools[2])}>добавить в корзину</button>
                            </div>
                        </div>
                        <div className='top-row'>
                            <div className='top-item'>
                                <div className='top-img-wrap' onClick={e => onItemClick(tools[3])}><Fade left><img className='top-img' src={tools[3].image} alt='строительные материалы'/></Fade></div>
                                <p className='top-title' onClick={e => onItemClick(tools[3])}>{tools[3].title}</p>
                                <Fade top><p className='top-price'>{tools[3].price} byn</p></Fade>
                                <button className='top-button' onClick={ e => addItem(tools[3])}>добавить в корзину</button>
                            </div>
                            <div className='top-item'>
                                <div className='top-img-wrap' onClick={e => onItemClick(tools[4])}><Fade left><img className='top-img' src={tools[4].image} alt='строительные материалы'/></Fade></div>
                                <p className='top-title' onClick={e => onItemClick(tools[4])}>{tools[4].title}</p>
                                <Fade top><p className='top-price'>{tools[4].price} byn</p></Fade>
                                <button className='top-button' onClick={ e => addItem(tools[4])}>добавить в корзину</button>
                            </div>
                            <div className='top-item'>
                                <div className='top-img-wrap' onClick={e => onItemClick(tools[5])}><Fade left><img className='top-img' src={tools[5].image} alt='строительные материалы'/></Fade></div>
                                <p className='top-title' onClick={e => onItemClick(tools[5])}>{tools[5].title}</p>
                                <Fade top><p className='top-price'>{tools[5].price} byn</p></Fade>
                                <button className='top-button' onClick={ e => addItem(tools[5])}>добавить в корзину</button>
                            </div>
                        </div>
                    </div>
                } 
            </div>
            <NavLink className='top-a' to='/catalog'>смотреть больше</NavLink>
            {
                showOk ? 
                <ModalAddSuccess onHide={e => setShowOk(false)}/>
                : null
            }
            {
                showItem ? <ModalItem item={item} onHide={e => setShowItem(false)} addToCart={addToCart}/> : null
            }
        </div>
    )
}
const mapStateToProps = (state) => ({
    tools: state.tools
});

const mapDispatchToProps = {
    fetchTop,
    addToCart
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(TopSales);
/*
<div key={idx} className='top-item'>
                            <div className='top-img-wrap'><Fade left><img className='top-img' src={item.image} alt='строительные материалы'/></Fade></div>
                            <p className='top-title'>{item.title}</p>
                            <p className='top-price'><Fade top>{item.price}</Fade></p> 
                            <button className='top-button' onClick={ e => addToCart (item)}>добавить в корзину</button> 
                        </div>
*/


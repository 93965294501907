import React from 'react'
import '../styles/footer.css'

import { NavLink } from 'react-router-dom'

function Footer() {
    return(
        <div className='footer'>
            <div className='wrapper-footer'>
                <div className='fot-logo'>
                    <img src='/images/logo.svg' alt='karat-service'/>
                    <div className='fot-h2'>2023 / Все права защищены</div>
                </div>
                <div className='fot-nav'>
                    <ul className='fot-container'>
                        <li className='fot-li'><NavLink className='fot-a' to='/catalog'>Каталог</NavLink></li>
                        <li className='fot-li'><NavLink className='fot-a' to='/about'>О нас</NavLink></li>
                        <li className='fot-li'><NavLink className='fot-a' to='/'>Главная</NavLink></li>
                    </ul>
                </div>
                <div className='fot-adr'>
                    <div className='fot-work'>
                        <img src='/images/glog.svg' alt='address'/>
                        <div className='fot-h4'>ул. Брестская, 29</div>
                    </div>
                    <div className='fot-work'>
                        <img src='/images/fottime.svg' alt='time'/>
                        <div className='fot-h4'>ПН - ПТ 8.00 - 17.00</div>
                    </div>
                </div>
                <div className='fot-phone'>
                    <div className='fot-work'>
                        <img src='/images/fotcall.svg' alt='tel'/>
                        <a className='fot-h4' href='tel:+375296820235'>+375 29 682 02 35</a>
                    </div>
                    <div className='fot-work'>
                        <img src='/images/fotcall.svg' alt='tel'/>
                        <a className='fot-h4' href='tel:+375296344046'>+375 29 634 40 46</a>
                    </div>
                    <div className='fot-work'>
                        <img src='/images/fotphone.svg' alt='phone'/>
                        <a className='fot-h4' href='tel:80177534798'>8 017 75 62 576</a>
                    </div>
                    <div className='fot-work'>
                        <img src='/images/fotemail.svg' alt='mail'/>
                        <a className='fot-h4' href='mailto:karat.servis@tut.by'>karat.servis@tut.by</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
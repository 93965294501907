import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Main from './pages/Main'
import Catalog from './pages/Catalog'
import About from './pages/AboutInfo'
import Cart from './pages/Cart'
import Search from './pages/Search'

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Main}/>
        <Route path='/catalog' exact component={Catalog}/>
        <Route path='/about' exact component={About}/>
        <Route path='/cart' exact component={Cart}/>
        <Route path='/search' exact component={Search}/>
      </Switch>   
    </Router>
  )
}

export default App

import React, { useState } from 'react'

import '../styles/modal.css'

import { sendEmail } from '../server/order.server'
import InputMask from 'react-input-mask'
import Fade from 'react-reveal/Fade'

function ModalForm ({ cartItems, onHide, setShowModalSuccess, totalPrice }) {
    const [isValidEmail, setIsValidEmail] = useState(true)
    const [isValidPhone, setIsValidPhone] = useState(true)
    const [success, setSuccess] = useState(false)
    const [load, setLoad] = useState(false)

    const emailRegex = /\S+@\S+\.\S{2,3}/
    const phoneRegex = /\+375+[ (]+[0-9]+[0-9]+[) ]+[0-9]+[0-9]+[ ]+[0-9]+[0-9]+[ ]+[0-9]{3}/
    let data = {}

    const validatePhone = (event) => {
        if (phoneRegex.test(event.target.value)) {
            setIsValidPhone(true)
        } else {
            setIsValidPhone(false)
        }
    }
    
    const validateEmail = (event) => {
        if (emailRegex.test(event.target.value)) {
          setIsValidEmail(true)
        } else {
          setIsValidEmail(false)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (isValidPhone) {
            data.name = data.name.value
            data.phone = data.phone.value
            data.email = data.email.value
    
            let items = ''
            cartItems.map(item => {
                items += `${item.title} (${item.brand}), ${item.price}, ${item.count} шт, итого на сумму: ${item.price * item.count} \n\n`
            })
            data.order = items
            data.total = totalPrice
    
            setLoad(true)
            sendEmail(data, setSuccess)
        }
        else {
            alert('Введите номер телефона')
        }
    }

    return <div className='modal'>
        <div className='modal__order'>
            <div className='modal__cancel_button' onClick={e => onHide()}></div>
            <form onSubmit={onSubmit} className='modal__form'>
                <p className='modal__title'>Контактные данные:</p>
                <div className='modal__item_wrap'>
                    <Fade left>
                    <p className='modal__item_p'>Имя</p>
                    </Fade>
                    <input className='modal__item_input' type='text' name="name" placeholder="Ваше имя" autocomplete='off' ref={ref => data.name = ref} required/>
                </div>
                <div className='modal__item_wrap'>
                    <Fade left>
                    <p className='modal__item_p'>Телефон</p>
                    </Fade>
                    <InputMask className='modal__item_input' style={{borderColor: isValidPhone ? 'rgba(35, 31, 28, 0.5)': 'rgba(236, 15, 15, 0.459)'}} placeholder="+375 (--) -- -- ---" mask="+375\ (99) 99 99 999" maskChar=" " onChange={validatePhone} ref={ref => data.phone = ref} required/>
                </div>
                    {
                        isValidPhone ? '' :
                        <p className='error'>введите корректный номер</p>
                    }
                <div className='modal__item_wrap'>
                    <Fade left>
                    <p className='modal__item_p'>Email</p>
                    </Fade>
                    <input className='modal__item_input' style={{borderColor: isValidEmail ? 'rgba(35, 31, 28, 0.5)': 'rgba(236, 15, 15, 0.459)'}} type='email' name="email" placeholder="email" autocomplete='off' onChange={validateEmail} ref={ref => data.email = ref}/>
                </div>
                    {
                        isValidEmail ? '' :
                        <p className='error'>введите корректный email, например, example@email.com</p>
                    }
                <button className='modal__order_button' type="submit">готово</button>
            </form>
        </div>
        {
            load ? <div className='modal__load'>
                <div className='modal_loader'><img className='loader' src='/images/loader.svg' alt='loading...'/></div>
            </div> : ''
        }
        {
            success ? ( onHide(), setShowModalSuccess(true)) : ''
        }
    </div>
}

export default ModalForm
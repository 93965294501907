import React from 'react'
import '../styles/aboutInfo.css'

import Fade from 'react-reveal/Fade'

import Head from '../components/Head'
import Footer from '../components/Footer'

function AboutInfo() {
    const body = document.querySelector('body')
    body.style.overflowY = 'auto'

    return (
        <div className='about-info'> 
            <Head/>
            <h2 className='top-h2'><img src='/images/Rectangle.svg' alt='square'/>  Наша компания  <img src='/images/Rectangle.svg' alt='square'/></h2>
            <div className='about__flex-container'>
            <Fade left>
                <img className='about__reg1' src='/images/rab1.png' alt='image'/>
                <div className='about__flex-container__item'>
                    <img className='about__image1' src='/images/abim1.png' alt='карат-сервис'/>
                    <div className='about__text1'>У нас вы найдёте то, что давно искали:
                        <br/><br/>У нас можно выбрать большинство материалов, необходимых для строительства
                        <br/><br/>Доступные цены. Дешевле стройматериалы купить просто невозможно. При этом мы следим за изменениями цен, чтобы предложить своим клиентам наиболее выгодное сочетание цена-качество
                        <br/><br/>Возможность доставки. Не достаточно стройматериалы купить – их необходимо еще и доставить. С нами вам не придется тратить на это время. Мы оперативно доставим Вашу покупку в указанное место
                        <br/><br/>Удобство заказа и оплаты, возможность консультации. Менеджер поможет определиться с выбором, подскажет, что лучше для решения определенной строительной задачи
                    </div>
                </div>
            </Fade>
            <Fade right>
                <div className='about__flex-container__item'>
                    <div className='about__text2'>Наша компания предлагает современные строительные материалы физическим лицам и организациям. Все представленные строительные материалы проверены на практике и имеют высокое качество, подтвержденное сертификатами.
                        <br/><br/>Ознакомиться со списком предлагаемых к продаже строительных материалов можно в каталоге нашего сайта и в магазине по адресу г. Жодино ул. Брестская, 29. Вы можете приобрести необходимые строительные материалы и оформить доставку
                        <br/><br/>Выбирая нас, вы получаете надежного партнера, который готов выполнять свои обязательства на высоком уровне. Вы можете рассчитывать на предоставление полной информации по предлагаемой строительной продукции и профессиональные рекомендации со стороны наших специалистов
                    </div>
                    <img className='about__reg2' src='/images/rab2.png' alt='rec'/>
                    <img className='about__image2' src='/images/abim2.svg' alt='image'/>
                </div>
            </Fade>
            </div>
            <h2 className='top-h2'><img src='/images/Rectangle.svg' alt='icon'/>  Сертификаты  <img src='/images/Rectangle.svg'/></h2>
            <Fade left cascade>
            <div className='sert'> 
                <img className='sert-img' src='/images/certificates/01.png' alt='сертификат'/>
                <img className='sert-img' src='/images/certificates/02.png' alt='сертификат'/>
                <img className='sert-img' src='/images/certificates/03.png' alt='сертификат'/>
                <img className='sert-img' src='/images/certificates/04.png' alt='сертификат'/>
                <img className='sert-img' src='/images/certificates/05.png' alt='сертификат'/>    
            </div>
            </Fade>
            <Footer/>
        </div>
    ) 
}

export default AboutInfo
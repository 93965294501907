let initState = JSON.parse(localStorage.getItem('cartItems')) || []

const ADD_TO_CART = 'ADD_TO_CART'
const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
const SHOW_CART = 'SHOW_CART'

const cartReducer = ( state = initState, action ) => {
    switch (action.type) {
        case SHOW_CART:
            return [...action.payload.cartItems]
        case ADD_TO_CART:
            return [...action.payload.cartItems]
        case REMOVE_FROM_CART:
            return action.payload.cartItems 
        default:
            return state
    }
}

export function addToCart (tool) {
    return (dispatch, getState) => {
        const cartItems = getState().cartItems
        let alreadyExists = false
        cartItems.forEach( item => {
            if (item.id === tool.id) {
                alreadyExists = true
                item.count++
            }
        })
        if (!alreadyExists) {
            cartItems.push({ ...tool, count: 1 })
        }
        dispatch({
            type: ADD_TO_CART,
            payload: { cartItems },
        })
        localStorage.setItem('cartItems', JSON.stringify(cartItems))
    }
}

export function decItemsCount (id) {
    return (dispatch, getState) => {
        const cartItems = getState().cartItems
        cartItems.forEach(item => {
            if ((item.id === id) && (item.count > 1)) {
                item.count--
            }
        })
        dispatch({ type: SHOW_CART, payload: { cartItems } })
        localStorage.setItem('cartItems', JSON.stringify(cartItems))
    }
}

export function incItemsCount (id) {
    return (dispatch, getState) => {
        const cartItems = getState().cartItems
        cartItems.forEach(item => {
            if ((item.id === id) && (item.count < 100)) {
                item.count++
            }
        })
        dispatch({ type: SHOW_CART, payload: { cartItems } })
        localStorage.setItem('cartItems', JSON.stringify(cartItems))
    }
}

export function removeFromCart (id) {
    return (dispatch, getState) => {
        const cartItems = getState().cartItems
        .filter(item => item.id !== id)
        dispatch({ type: REMOVE_FROM_CART, payload: { cartItems } })
        localStorage.setItem('cartItems', JSON.stringify(cartItems))
    }
}


export default cartReducer
import React, { useState } from 'react'

import '../styles/tools.css'

import ModalItem from './ModalItem'
import ModalAddSuccess from './ModalAddSuccess'

import Fade from 'react-reveal/Fade'

function ToolsList({ tools, addToCart }) {
    const [showItem, setShowItem] = useState(false)
    const [showOk, setShowOk] = useState(false)
    const [item, setItem] = useState()

    const addItem = (item) => {
        addToCart (item)
        setShowOk(true)
    }

    const onItemClick = (item) => {
        setItem(item)
        setShowItem(true)
    }

    return(
        <div className='list'>
        {
            (tools.length == 0) ? <div className='wrapper-loader'><img className='loader' src='/images/loader.svg' alt='loading...'/></div>: null
        }
        {   
            tools.map(
                    (item, idx) =>
                        <div key={item.id} className='list-item'>
                            <div className='list-img-wrap'><Fade left><img className='list-img' onClick={e => onItemClick(item)} src={item.image} alt='строительные материалы'/></Fade></div>
                            <div className='list-title-wrap'>
                                <Fade top><p className='list-title' onClick={e => onItemClick(item)}>{item.title}</p></Fade>
                                {
                                    item.price == 0 ? 
                                    <div className='list-price'>нет в наличии</div>
                                    :
                                    <>
                                    <div className='list-price'>{item.price} byn</div>
                                    <button className='tool-button' onClick={ e =>  addItem(item)}>добавить в корзину</button>
                                    </>
                                }
                                
                            </div>
                        </div>
            )
        }  
        {
                showOk ? 
                <ModalAddSuccess onHide={e => setShowOk(false)}/>
                : null
        }
        {
                showItem ? <ModalItem item={item} onHide={e => setShowItem(false)} addToCart={addToCart}/> : null
        }       
         
        </div>
    )
}

export default ToolsList

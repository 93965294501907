import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import '../styles/cart.css'
import { removeFromCart, decItemsCount, incItemsCount } from '../store/cart.reducer'

import Head from '../components/Head'
import ModalForm from '../components/ModalForm'
import ModalFormSuccess from '../components/ModalFormSuccess'
import ModalRemove from '../components/ModalRemove'
import Footer from '../components/Footer'
import Fade from 'react-reveal/Fade'

function Cart ({ removeFromCart, decItemsCount, incItemsCount, cartItems }) {

    const [showModal, setShowModal] = useState(false)
    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [showModalRemove, setShowModalRemove] = useState(false)
    const [itemToRemove, setItemToRemove] = useState()
    const [total, setTotal] = useState()

    const body = document.querySelector('body')
    body.style.overflowY = 'auto'

    useEffect(() => {
        let totalPrice = 0
        cartItems.map(item => {
            totalPrice += item.price * item.count
        })

        setTotal(totalPrice.toFixed(2))
    }, [cartItems])

    return (
        <>
        <Head/>
        <div className='cart-wrapper'>
            {
                cartItems.length ? 
                <>
                <div className='cart'> 
                {
                     cartItems.map(item => 
                    <div className='cart__item'>
                        <Fade left>
                        <div className='item__image'>
                            <div className='cart__image_wrapper'><img className='cart__image' src={item.image}></img></div>
                        </div>
                        </Fade>
                        <Fade bottom cascade>
                        <div className='item__title'>
                            <p className='item__title1'>{item.title}</p>
                            <p className='item__title2'>Производитель: {item.brand}</p>
                        </div>
                        </Fade>
                        <div className='item__price_mobile'>
                            {item.price}
                        </div>
                        <div className='item__count'>
                            <button className='item__count_button' onClick={ e => decItemsCount(item.id)}>-</button>
                            {item.count}
                            <button className='item__count_button' onClick={ e => incItemsCount(item.id)}>+</button>
                        </div> 
                        <div className='cart_hr'></div>
                        <div className='item__sum'>
                            <p className='item__sum1'>Цена:</p>
                            <Fade top>
                            <p className='item__sum2'>{item.price} BYN</p>
                            </Fade>
                        </div>
                        <div className='item__sum_total'>
                            <p className='item__sum1'>Итого:</p>
                            <Fade top>
                            <p className='item__sum2'>{(item.price * item.count).toFixed(2)} BYN</p>
                            </Fade>
                        </div>
                        <div className='item__total_mobile'>
                            {(item.price * item.count).toFixed(2)} BYN
                        </div>
                        <div className='item__remove' onClick={ e => ( setShowModalRemove(true), setItemToRemove(item.id))}></div>
                    </div>) 
                }
                </div>
                <Fade cascade top>
                    <div className='cart__total'>
                        <p className='cart__total_p'>ИТОГО:</p>
                        <p className='cart__total_p'>{total} BYN</p>
                    </div>
                </Fade>
                <button className='cart__order_button' onClick={ e => setShowModal(true)}>Оформить заказ</button>
                </>
                :
                <div className='cart_empty'>Ваша корзина пуста :(</div>
            }     
        </div>
        <Footer/>
        {
            showModal ? 
            <ModalForm onHide={e => setShowModal(false)} setShowModalSuccess={setShowModalSuccess} cartItems={cartItems} totalPrice={total}/> :
            null
        }
        {
            showModalSuccess ? 
            <ModalFormSuccess onHide={e => setShowModalSuccess(false)}/> :
            null
        }
        {
            showModalRemove ? 
            <ModalRemove onHide={e => setShowModalRemove(false)} removeFromCart={e => removeFromCart(itemToRemove)}/> :
            null
        }
        </>
    ) 
}

const mapStateToProps = (state) => ({
    cartItems: state.cartItems
})

const mapDispatchToProps = {
    removeFromCart,
    decItemsCount,
    incItemsCount
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Cart)
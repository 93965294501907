import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import toolsReducer from './tools.reducer'
import cartReducer from './cart.reducer'

const reducers = combineReducers({
    tools: toolsReducer,
    cartItems: cartReducer
})

const store = createStore(reducers, applyMiddleware(thunk))

export default store
import React from 'react'
import '../styles/advantages.css'

import Fade from 'react-reveal/Fade'

function Advantages() {
    return(
        <div className='main'>
            <h1 className='ad-h2'><img src='/images/Rectangle.svg' alt='square'/>  Почему мы?  <img src='/images/Rectangle.svg' alt='square'/></h1>
            <Fade left cascade>
            <div className='adv'>
                <div className='adv-item'>
                    <div className='adv-img'><img src='/images/data.svg' alt='range'/></div>
                    <h2 className='adv-h'>Обновляемый ассортимент</h2>
                    <hr className='adv-hr'/>
                    <h3 className='adv-p'>Большой выбор материалов лучших производителей</h3>
                </div>
                <div className='adv-item'>
                    <div className='adv-img'><img src='/images/car.svg' alt='delivery'/></div>
                    <h2 className='adv-h'>Быстрая доставка</h2>
                    <hr className='adv-hr'/>
                    <h3 className='adv-p'>Доставка в любое место в Жодино</h3>
                </div>
                <div className='adv-item'>
                    <div className='adv-img'><img src='/images/discount.svg' alt='price'/></div>
                    <h2 className='adv-h'>Приятные цены</h2>
                    <hr className='adv-hr'/>
                    <h3 className='adv-p'>Самые низкие цены в городе, налаженная система скидок</h3>
                </div>
                <div className='adv-item'>
                    <div className='adv-img'><img src='/images/chat.svg' alt='consultation'/></div>
                    <h2 className='adv-h'>Консультации</h2>
                    <hr className='adv-hr'/>
                    <h3 className='adv-p'>Ответы профессионалов на любые ваши вопросы</h3>
                </div>
            </div>
            </Fade>
        </div>
    )
}

export default Advantages
import React from 'react'
import '../styles/about.css'

import Fade from 'react-reveal/Fade'

function About() {
    return(
        <div className='about'>
            <Fade right>
            <h2 className='about-h2'><img src='/images/Rectangle.svg' alt='square'/>  Наша компания  <img src='/images/Rectangle.svg' alt='square'/></h2>
            <img src='/images/rect.svg' className='img-rect' alt='жодино'/>
            <img src='/images/rect2.svg' className='img-rect2' alt='square'/>
            <img src='/images/img.png' className='img-img' alt='image'/>
            <img src='/images/2.svg' className='img-p2' alt='image'/>
            <img src='/images/3.svg' className='img-p3' alt='image'/>
            <div className='about-p1'>Наша компания предлагает современные строительные материалы физическим лицам и организациям. Все представленные строительные материалы проверены на практике и имеют высокое качество, подтвержденное сертификатами</div>
            <div className='about-p2'>Ознакомиться со списком предлагаемых к продаже строительных материалов можно в каталоге нашего сайта и в магазине по адресу г. Жодино ул. Брестская, 29. Вы можете приобрести необходимые строительные материалы и оформить доставку</div>
            <div className='about-p3'>Выбирая нас, вы получаете надежного партнера, который готов выполнять свои обязательства на высоком уровне. Вы можете рассчитывать на предоставление полной информации по предлагаемой строительной продукции и профессиональные рекомендации со стороны наших специалистов</div>
            </Fade>
        </div>
    )
}

export default About
import React from 'react'

import '../styles/modal.css'

function ModalAddSuccess ({ onHide }) {
    return <div className='modal'>
        <div className='modal__success'>
            <div className='modal__cancel_button' onClick={e => onHide()}></div>
            <div className='success__image'></div>
            <p className='success__title'>Товар добавлен!</p>
        </div>
    </div>
}

export default ModalAddSuccess
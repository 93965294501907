import React from 'react'

import '../styles/modal.css'

function ModalRemove ({ onHide, removeFromCart }) {
    return <div className='modal'>
        <div className='modal__remove'>
            <p className='remove__title'>Хотите удалить товар из корзины?</p>
            <div className='modal__remove_buttons'>
                <div className='remove_button_cancel' onClick={e => onHide()}>отмена</div>
                <div className='remove_button' onClick={e => ( removeFromCart(), onHide() )}>да</div>
            </div>
        </div>
    </div>
}

export default ModalRemove
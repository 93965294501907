import emailjs from 'emailjs-com'

export function sendEmail (data, setSuccess) {

    emailjs.send('service_5uwikch', 'template_j6u9hum', {name: data.name, phone: data.phone, email: data.email, order: data.order, total: data.total}, 'user_oo9ksEOj7M35pILlDmRNq')
    .then( res => {
        setSuccess(true)
    }, () => {
        setSuccess(false)
    })    
}
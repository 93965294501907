import React from 'react'
import '../styles/header.css'

import { NavLink } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

function Header() {
    return(
        <div className='header'>
            <div className='wrapper-header'>
                <Fade top cascade>
                    <h1 className='header-h1'>Магазин строительных материалов в Жодино с доставкой</h1>
                    <h2 className='header-h2'>Лучших производителей по лучшим ценам</h2>
                    <NavLink className='header-a' to='/catalog'>в каталог</NavLink>
                </Fade>
                <div className='sale'>
                    <img src='/images/sale.svg' alt='акция'/>
                    <div className='sale-info'>
                        <img src='/images/1.png' alt='акционный товар' className='sale-img'/>
                        <div className='sale-sale'>
                            <Fade top cascade>
                                <h3 className='sale-title'>37.00 BYN</h3>
                                <h4 className='sale-description'>Клеевой состав GEOFLEX применяется для облицовки поверхностей стен и полов внутри и снаружи зданий керамической плиткой, мозаикой, ГРЕС, натуральным и искусственным камнем размером до 3 м². </h4>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchTools, showTools, filterTools, findTools } from '../store/tools.reducer'
import { addToCart } from '../store/cart.reducer'

import Head from '../components/Head'
import ToolsList from '../components/ToolsList'
import Footer from '../components/Footer'
import Menu from '../components/Menu'

import '../styles/main.css'

function Catalog({ fetchTools, tools, addToCart, cartItems }) {
    const body = document.querySelector('body')
    body.style.overflowY = 'auto'
    let search = window.location.search.slice(8)
    
    useEffect(() => {
       fetchTools(search)      
	},[])

    useEffect(() => {
    }, [tools])
    
    return (<div>
        <Head/>
        <div className='wrapper-catalog'>
            <Menu/>
            <ToolsList tools={tools} addToCart={addToCart}/>
        </div>
        <Footer/>
    </div>) 
}

const mapStateToProps = (state) => ({
    tools: state.tools,
    cartItems: state.cartItems
})

const mapDispatchToProps = {
    fetchTools,
    showTools,
    filterTools,
    findTools,
    addToCart
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Catalog)
